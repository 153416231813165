//Packages
import React from "react";
import Grid from "@mui/material/Grid";
//Components
import HeadingBanner from "../components/HeadingBanner";
//Assets
import { Typography } from "@mui/material";

const Deepawali24 = ({ eventtheme }) => {
  return (
    <>
      <Grid item md={6} xs={12}>
        <HeadingBanner label={eventtheme} />
        <Typography style={{ paddingInline: "1.5rem", marginBlock: "2%" }}>
          On the
          <b style={{ fontSize: "20px" }}> auspicious </b>
          occassion of Diwali 2024,
          <b style={{ fontSize: "20px" }}> The Colorist </b>organised the art
          exhibition.
          <br /> <br />
          <b style={{ fontSize: "20px" }}>
            The Colorist proudly showcased their exquisite collection at
            Deepawali Kala Mela 2024, held at Mahamana Malviya Inter College,
            Muzaffar Nagar.
          </b>
          <br /> <br /> The exhibition featured stunning paintings of Lord
          Ganesh Ji, crafted on eco-friendly jute fabric and canvas using
          vibrant acrylic colors. Complementing the artwork were beautifully
          repurposed glass bottles wrapped in intricate jute threads, blending
          creativity with sustainability. This unique display seamlessly wove
          tradition and eco-consciousness, inspiring visitors to see the
          potential in sustainable materials. The event captured the true
          essence of Deepawali—celebrating culture, art, and a brighter, greener
          future for generations to come.
          <br />
          <br />
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        paddingLeft="auto"
        justifyContent="center"
        textAlign="center"
      >
        <video autoPlay controls loop height="80%">
          <source
            src={
              "https://res.cloudinary.com/the-perception/video/upload/v1737618872/TheColorist_2024_Deepawali_ojgy22.mp4"
            }
            type="video/mp4"
          />
        </video>
      </Grid>
    </>
  );
};

export default Deepawali24;
