//Packages
import React from "react";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";

//Components
import HeadingBanner from "../components/HeadingBanner";

//Assets
import { Typography } from "@mui/material";
import eventimg from "../assets/thecolorist_bg.jpg";

const Vasantotsav2024 = ({ eventtheme }) => {
  return (
    <>
      <Grid container columns={12} pt={12}>
        {eventtheme == "Basant Utsav 2024" && (
          <>
            <Grid item md={6} xs={12}>
              <HeadingBanner label={eventtheme} />
              <Typography style={{ paddingInline: "1.5rem", marginTop: "2%" }}>
                In March 2024, the{" "}
                <b style={{ fontSize: "20px" }}>
                  Hand-painted man-made bee hives
                </b>{" "}
                created by
                <b style={{ fontSize: "20px" }}> The Colorist</b> Team for Jyoti
                Gramodyog Sansthan in Saharanpur were once again a part of the
                Basant Utsav Festival. This year, the bee
                hives were displayed at two places :{" "}
                <b style={{ fontSize: "20px" }}>
                  Raj Bhawan, Dehradun, Uttarakhand,{" "}
                </b>{" "}
                and at the{" "}
                <b style={{ fontSize: "20px" }}>
                  Chief Minister's House, Dehradun, Uttarakhand{" "}
                </b>{" "}
                , marking an even more special presentation. <br/><br/>These intricate
                hives, showcasing a unique blend of artistry and functionality,
                stood as a testament to local craftsmanship and sustainable
                practices.
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              justifyContent="center"
              textAlign="center"
            >
              <CardMedia
                component="img"
                sx={{
                  height: { xs: "20vh", md: "100%" },
                  marginLeft: "auto",
                  padding: "0.2em",
                  border: "0.5rem solid white",
                  boxShadow: "inset 0px 4px 4px rgba(0, 0, 5, 0.4)",
                  objectFit: "contain",
                }}
                className="hovericon"
                image="https://res.cloudinary.com/the-perception/image/upload/v1737626802/Basant_Utsav_2024_The_Colorist_idmgm7.jpg"
                alt={eventimg}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Vasantotsav2024;
