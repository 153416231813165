import { useReducer } from "react";
import axios from "axios";
import ArtistContext from "./artistContext";
import artistReducer from "./artistReducer";
import {
  GET_ARTISTS,
  ADD_ARTIST,
  DELETE_ARTIST,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_ARTIST,
  FILTER_ARTISTS,
  CLEAR_FILTER,
  ARTIST_ERROR,
  CLEAR_ARTISTS,
} from "../types";

const ArtistState = (props) => {
  const initialState = {
    artists: null,
    current: null,
    filtered: null,
    error: null,
    artworksloading: true,
  };
  const url = process.env.REACT_APP_API_URL;

  const [state, dispatch] = useReducer(artistReducer, initialState);
  // Get Contacts
  const getArtists = async () => {
    try {
      const res = await axios.get(`${url}/api/artist`);
      dispatch({
        type: GET_ARTISTS,
        payload: res?.data,
      });
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err?.response?.msg,
      });
    }
  };

  // Add Contact
  const addArtist = async (artist) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post(`${url}/api/artist`, artist, config);

      dispatch({
        type: ADD_ARTIST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err?.response?.msg,
      });
    }
  };

  // Delete Contact
  const deleteArtist = async (id) => {
    try {
      await axios.delete(`${url}/api/artist/${id}`);

      dispatch({
        type: DELETE_ARTIST,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err?.response?.msg,
      });
    }
  };

  // Update Contact
  const updateArtist = async (artist) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `${url}/api/artist/${artist._id}`,
        artist,
        config
      );

      dispatch({
        type: UPDATE_ARTIST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err?.response?.msg,
      });
    }
  };

  // Clear Contacts
  const clearArtists = () => {
    dispatch({ type: CLEAR_ARTISTS });
  };

  // Set Current Contact
  const setCurrent = (artist) => {
    dispatch({ type: SET_CURRENT, payload: artist });
  };

  // Clear Current Contact
  const clearCurrent = () => {
    console.log("hi clear");
    dispatch({ type: CLEAR_CURRENT });
  };

  // Filter Contacts
  const filterArtists = (text) => {
    dispatch({ type: FILTER_ARTISTS, payload: text });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER });
  };

  return (
    <ArtistContext.Provider
      value={{
        artists: state.artists,
        current: state.current,
        filtered: state.filtered,
        error: state.error,
        artworksloading: state.artworksloading,
        addArtist,
        deleteArtist,
        setCurrent,
        clearCurrent,
        updateArtist,
        filterArtists,
        clearFilter,
        getArtists,
        clearArtists,
      }}
    >
      {props.children}
    </ArtistContext.Provider>
  );
};

export default ArtistState;
