import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import breakpoints from "../essentials/screensize";
import Grid from "@mui/material/Grid";
import MenuList from "@mui/material/MenuList";
//image
import Logo from "../assets/The_Colorist.png";
import NavButton from "../components/NavButton";

const LogoIcon = styled.div`
.logo{
  width: 100px;
  height:1vh;
  // margin-top: 0.7vh;
    // @media (max-width: ${breakpoints.sm}px) {
    //   width: 15.5vw;
    //   margin-top: 0.5rem; 
    // }
    // @media (max-width: ${breakpoints.md}px) {
    //   width: 2.5vw;
    //   margin-top: 0.5rem;
    // }
  }}
`;
const ProfileIcon = styled.img`
width: 2rem;
height:2rem;
margin-right: 0.5rem;
border-radius:50%;
    @media (max-width: ${breakpoints.sm}px) {
      width:1.8rem;
      height:1.8rem;
      margin-right: 0.3rem;
    }
  }
`;

const Navbar = ({ icon }) => {
  let navigate = useNavigate();
  const [anchorEluser, setAnchorElUser] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [validate, setValidate] = useState("");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const authLinks = [
    <MenuItem key={1}>
      <IconButton size="small" aria-label="logout" style={{ color: "grey" }}>
        <Logout />
        <Typography variant="body2" align="center">
          Logout
        </Typography>
      </IconButton>
    </MenuItem>,
  ];

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          sx={{
            background: { md: "transparent", xs: "whitesmoke" },
            paddingInline: "2.8rem",
            position: "fixed",
            textAlign: "right",
            zIndex: 5,
            width: "100%",
          }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Grid
                container
                columns={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={7} md={12}>
                  <Box
                    sx={{
                      display: { xs: "flex", md: "none" },
                      flexDirection: "row-reverse",
                      marginLeft: { xs: "-4vh", md: 0 },
                      float: "left",
                      marginRight: "auto",
                    }}
                  >
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      style={{ color: "#8e8e8e" }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{
                        display: { sx: "block", md: "none" },
                      }}
                    >
                      <MenuList
                        onClick={handleCloseNavMenu}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "left",
                        }}
                      >
                        <MenuItem>
                          <Link to="/">
                            <IconButton
                              size="small"
                              aria-label="home"
                              style={{
                                color: "grey",
                              }}
                              label="Home"
                            >
                              <img
                                src={Logo}
                                alt={Logo}
                                style={{
                                  width: "100px",
                                  margin: "auto",
                                  display: "block",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/home">
                            <IconButton
                              size="large"
                              aria-label="home"
                              style={{
                                color: "grey",
                              }}
                              label="Home"
                            >
                              <Typography variant="body2">Home</Typography>
                            </IconButton>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/">
                            <IconButton
                              size="large"
                              aria-label="aboutus"
                              style={{
                                color: "grey",
                              }}
                              label="Home"
                            >
                              <Typography variant="body2">About Us</Typography>
                            </IconButton>
                          </Link>
                        </MenuItem>

                        <MenuItem>
                          <Link to="/artworks">
                            <IconButton
                              size="large"
                              aria-label="artworks"
                              style={{
                                color: "grey",
                              }}
                            >
                              <Typography variant="body2">Artworks</Typography>
                            </IconButton>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/artists">
                            <IconButton
                              size="large"
                              aria-label="artists"
                              style={{
                                color: "grey",
                              }}
                            >
                              <Typography variant="body2">Artists</Typography>
                            </IconButton>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/events">
                            <IconButton
                              size="large"
                              aria-label="events"
                              style={{
                                color: "grey",
                              }}
                            >
                              <Typography variant="body2">Events</Typography>
                            </IconButton>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/ourservices">
                            <IconButton
                              size="large"
                              aria-label="services"
                              style={{
                                color: "grey",
                              }}
                            >
                              <Typography variant="body2">Services</Typography>
                            </IconButton>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/contact-us">
                            <IconButton
                              size="large"
                              aria-label="contactus"
                              style={{
                                color: "grey",
                              }}
                            >
                              <Typography variant="body2">Contact Us</Typography>
                            </IconButton>
                          </Link>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "flex", md: "none" },
                    }}
                  >
                    <img
                      onClick={() => navigate("/")}
                      src={Logo}
                      alt={Logo}
                      pl={-2}
                      style={{
                        width: "20vh",
                        margin: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: "auto",
                      justifyContent: "right",
                      paddingLeft: "auto",
                    }}
                  >
                    <Link to="/">
                      <img
                        src={Logo}
                        alt={Logo}
                        style={{
                          width: "30%",
                          display: "block",
                          marginRight: "auto",
                          marginLeft: "-6vw",
                        }}
                      />
                    </Link>
                    <Link to="/home">
                      <NavButton label="Home" />
                    </Link>
                    <Link to="/">
                      <NavButton label="About Us" />
                    </Link>
                    <Link to="/artists">
                      <NavButton label="Artists" />
                    </Link>
                    <Link to="/artworks">
                      <NavButton label="Artworks" />
                    </Link>
                    <Link to="/events">
                      <NavButton label="Events" />
                    </Link>
                    <Link to="/ourservices">
                      <NavButton label="Services" />
                    </Link>
                    <Link to="/contact-us">
                      <NavButton label="Contact Us" />
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </Grid>
      </Box>
    </>
  );
};

Navbar.propTypes = {
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  icon: Logo,
};

export default Navbar;
