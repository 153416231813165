//Packages
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
//Assets
//Components
import HeadingBanner from "../components/HeadingBanner";

const Events = () => {
  let navigate = useNavigate();
  return (
    <Grid
      container
      columns={12}
      display="flex"
      sx={{ paddingTop: { xs: "0vh", md: "15vh" } }}
    >
      <Grid container columns={12} style={{ minHeight: "fit-content" }}>
        <Grid item md={12} xs={6} sx={{ marginTop: { xs: "10vh", md: "0%" } }}>
          <HeadingBanner label="Previous Events" />
        </Grid>
        {/* Vasantotsav Festival */}
        <Grid
          item
          md={12}
          xs={12}
          style={{ marginTop: "1rem", cursor: "pointer" }}
          onClick={async () =>
            navigate(`/event/BasantUtsav_Festival`, {
              state: "Basant Utsav Festival",
            })
          }
        >
          <div
            style={{
              backgroundColor: "#d9d9d9",
              margin: "1rem",
              padding: "1rem",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Basant Utsav Festival at the Raj Bhawan in Dehradun, Uttarakhand -
            March 3rd to March 5th, 2023.
            <ArrowForwardIosIcon />
          </div>
        </Grid>
        {/* Lord Ganesha's Paintings Exhibition */}
        <Grid
          item
          md={12}
          xs={12}
          style={{ cursor: "pointer" }}
          onClick={async () =>
            navigate(`/event/Lord_Ganesha_Exhibition`, {
              state: "Lord Ganesha's Paintings Exhibition",
            })
          }
        >
          <div
            style={{
              backgroundColor: "#d9d9d9",
              margin: "1rem",
              padding: "1rem",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Lord Ganesha's Paintings - Exhibition, Pre - Diwali Art Mela in
            Uttar Pradesh, 9th November 2023
            <ArrowForwardIosIcon />
          </div>
        </Grid>
        {/* Vasantotsav 2024*/}
        <Grid
          item
          md={12}
          xs={12}
          style={{ cursor: "pointer" }}
          onClick={async () =>
            navigate(`/event/BasantUtsav_2024`, {
              state: "Basant Utsav 2024",
            })
          }
        >
          <div
            style={{
              backgroundColor: "#d9d9d9",
              margin: "1rem",
              padding: "1rem",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Basant Utsav Festival at the Raj Bhawan and CM House in Dehradun,
            Uttarakhand - March 2024.
            <ArrowForwardIosIcon />
          </div>
        </Grid>
        {/* Deepawali Kala Mela 2024*/}
        <Grid
          item
          md={12}
          xs={12}
          style={{ marginBottom: "1rem", cursor: "pointer" }}
          onClick={async () =>
            navigate(`/event/Deepawali_Kala_Mela_2024`, {
              state: "Deepawali Kala Mela 2024",
            })
          }
        >
          <div
            style={{
              backgroundColor: "#d9d9d9",
              margin: "1rem",
              padding: "1rem",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Deepawali Kala Mela 2024 at Mahamana Malviya Inter College, Muzaffar
            Nagar, Uttar Pradesh, 25 - 27 October 2024
            <ArrowForwardIosIcon />
          </div>
        </Grid>
        <Grid item md={12}>
          <HeadingBanner label="Upcoming Events" />
        </Grid>
        <Grid
          item
          height={"15vh"}
          md={12}
          xs={12}
          style={{ marginBlock: "2rem", cursor: "pointer" }}
          onClick={async () =>
            navigate(`/event/Lord Ganesha Exhibition`, {
              state: "Lord Ganesha's Paintings Exhibition",
            })
          }
        >
          {/*  <div
            style={{
              backgroundColor: "#d9d9d9",
              margin: "1rem",
              padding: "1rem",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Lord Ganesha's Paintings - Exhibition, Pre - Diwali Art Mela, 9th November 2023
            <ArrowForwardIosIcon />
          </div>*/}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Events;
