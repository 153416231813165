// Packages
import React, { Suspense, lazy, Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled from "styled-components";
// Pages
import Navbar from "./layout/Navbar";
import Home from "./pages/Home";
// Styles
import "./App.css";
import breakpoints from "./essentials/screensize";
//Pages
import AboutUs from "./pages/AboutUs";
import Footer from "./components/Footer";
import Artists from "./pages/Artists";
import EventInfo from "./pages/EventInfo";
import Events from "./pages/Events";
import ArtistInfo from "./pages/ArtistInfo";
import Artworks from "./pages/Artworks";
import ArtworkInfo from "./pages/ArtworkInfo";
import OurServices from "./pages/OurServices";
//State
import ArtworkState from "./context/artwork/ArtworkState";
import ArtistState from "./context/artist/ArtistState";
//Components
import Spinner from "./components/Spinner";
import ContactUs from "./components/ContactUs";

const Container = styled.div`
  justify-content: center;
  background-color: whitesmoke;
  height: auto;
  @media (max-width: ${breakpoints.sm}px) {
    // padding: 21vw 6vw;
  }
`;
const Admin = lazy(() => import("./admin/Admin"));
const ArtistUpload = lazy(() => import("./admin/pages/ArtistsPage"));
const ArtworkUpload = lazy(() => import("./admin/pages/ArtworksPage"));

const App = () => {
  return (
    <ArtworkState>
      <ArtistState>
        <BrowserRouter>
          <Suspense
            fallback={
              <div
                style={{
                  height: "100%",
                  backgroundSize: "cover",
                }}
              >
                <Spinner />
              </div>
            }
          >
            <Fragment>
              <Navbar />
              <Container>
                <Routes>
                  <Route path="/home" element={<Home />} />
                  <Route path="/" element={<AboutUs />} />
                  <Route path="/artists" element={<Artists />} />
                  <Route path="/artworks" element={<Artworks />} />
                  <Route path="/events" element={<Events />} />
                  <Route path="/artist/:id" element={<ArtistInfo />} />
                  <Route path="/artwork/:id" element={<ArtworkInfo />} />
                  <Route path="/event/:id" element={<EventInfo />} />
                  <Route path="/ourservices" element={<OurServices />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/artists-upload" element={<ArtistUpload />} />
                  <Route path="/artworks-upload" element={<ArtworkUpload />} />
                </Routes>
              </Container>
              <Footer />
            </Fragment>
          </Suspense>
        </BrowserRouter>
      </ArtistState>
    </ArtworkState>
  );
};

export default App;
